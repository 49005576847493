<template>
  <div id="components">
    <News :showAll="true" :navigateToMusic="scrollToMusic" />
  </div>
</template>

<script>

import router from "@/router";
import News from "@/components/News";

export default {
  name: 'News-View',
  components: {News},
  methods: {
    scrollToMusic() {
      router.push('/music');
    }
  }
}
</script>
